import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/Seo";


// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title="Not found"
      />
      <Container className="py-5">
        <Row>
          <Col md={12}>
            <h1>Page not found</h1>
            <p>
              Sorry{" "}
              <span role="img" aria-label="Pensive emoji">
                😔
              </span>{" "}
              we couldn’t find what you were looking for.
              <br />
              {process.env.NODE_ENV === "development" ? (
                <>
                  <br />
                  Try creating a page in <code>src/pages/</code>.
                  <br />
                </>
              ) : null}
              <br />
              <Link to="/">Go home</Link>.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
